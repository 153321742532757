import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useMount } from 'react-use'
import prefetchImages from 'prefetch-image'
import { forEach } from 'lodash'

import { Layout, Drawer } from '../components'
import {
  container,
  padding,
  colours,
  bgImage,
  bgIcon,
  wrapper,
  type,
  pagePaddingTop,
  introText,
  textHover,
} from '../styles/global'
import { parseACF } from '../utils'
import { media, useBreakpoint } from '../styles/utils'

const OfficeExpertise = (props) => {
  const data =
    props.previewData ||
    parseACF(useStaticQuery(query), 'allWordpressInfopages')
  const groupImage = data?.group_image
  const [teamImage, setTeamImage] = useState({
    image: data?.group_image,
    caption: true,
  })
  const [activePerson, setActivePerson] = useState(false)
  const [drawerActive, setDrawerActive] = useState(false)
  const isPhone = useBreakpoint('phone')

  useMount(() => {
    preloadImages()
  })

  useEffect(() => {
    document.body.style.overflow = drawerActive ? 'hidden' : 'auto'
  }, [drawerActive])

  const preloadImages = () => {
    if (!data.people) return

    const images = []

    forEach(data.people, (person) => {
      images.push(person.image?.sizes?.medium2)
    })

    prefetchImages(images)
  }

  const setPerson = (person) => {
    setActivePerson(person)
    setTeamImage({ image: person.image, caption: false })

    if (isPhone) {
      setTimeout(() => setDrawerActive(true), 500)
    } else {
      setDrawerActive(true)
    }
  }

  const onDrawerClose = () => {
    if (!drawerActive) return

    setDrawerActive(false)
    setActivePerson(false)
    setTeamImage({ image: groupImage, caption: true })
  }

  const renderDrawer = () => {
    console.log(activePerson)
    return (
      <Drawer
        position={'right'}
        active={drawerActive}
        styles={drawerStyles}
        outsideClick={onDrawerClose}
      >
        <Person>
          <DrawerTop>
            <Heading>Expertise Profile</Heading>
            <Toggle onClick={onDrawerClose} inMenu>
              Close
            </Toggle>
          </DrawerTop>

          <ScrollWrapper>
            {activePerson?.image && (
              <PersonImage image={activePerson?.image?.sizes?.medium2} />
            )}

            <Heading
              dangerouslySetInnerHTML={{
                __html: `
								${activePerson.name}<br/>
								${activePerson.role}
							`,
              }}
            />

            <Text
              dangerouslySetInnerHTML={{
                __html: `
								${activePerson.biography}
							`,
              }}
            />

            <Subheading
              as={'a'}
              href={`mailto:${activePerson?.email}`}
              target={'_blank'}
            >
              {`${activePerson?.email}`}
            </Subheading>
          </ScrollWrapper>
        </Person>
      </Drawer>
    )
  }

  const renderText = () => {
    if (!data.introduction) return

    return (
      <Intro>
        <Text dangerouslySetInnerHTML={{ __html: data.introduction }} />
      </Intro>
    )
  }

  const renderNav = () => {
    if (!data.people) return

    const items = data.people.map((item, i) => {
      if (!item.display) return

      return (
        <Item
          key={i}
          onMouseEnter={() =>
            !drawerActive && setTeamImage({ image: item.image, caption: false })
          }
          onMouseLeave={() =>
            !drawerActive && setTeamImage({ image: groupImage, caption: true })
          }
          onClick={() => setPerson(item)}
        >
          {item.name}
        </Item>
      )
    })

    return <Nav>{items}</Nav>
  }

  const renderTeamImage = () => {
    return (
      <TeamImageWrap>
        <TeamImage image={teamImage?.image.sizes?.medium2} />
        {teamImage.caption && (
          <Text dangerouslySetInnerHTML={{ __html: data.group_caption }} />
        )}
      </TeamImageWrap>
    )
  }

  const renderTeam = (params) => {
    return (
      <Team>
        {renderNav()}
        {renderTeamImage()}
      </Team>
    )
  }

  return (
    <Layout meta={data.seo} breadcrumb={data.title}>
      <Container>
        {renderText()}
        {renderTeam()}
      </Container>

      {renderDrawer()}
    </Layout>
  )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Item = styled.div``

const Image = styled.div`
  background-image: url(${(props) => props.image});
  ${bgIcon};
  transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
  ${container}
  ${padding}
    ${pagePaddingTop}
	flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 160px;
`

// Intro

const Intro = styled.div`
  ${introText}
  margin-bottom: 110px;
`

// Team

const Team = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  ${media.phone`
		flex-direction: column;
	`}
`

// Nav

const Nav = styled.div`
  width: 100%;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${Item} {
    ${type.bodySmall}
    ${textHover}
  }

  ${media.phone`
		position: initial;
		margin-bottom: 50px;

		${Item} {
			${type.body}
			line-height: 1.2em;
		}
	`}
`

// Team Image

const TeamImageWrap = styled.div`
  width: 51.42vw;
  position: relative;

  ${Text} {
    ${type.caption};
    padding: 0 10.2vw;
    position: absolute;
    bottom: -16px;
    transform: translateY(100%);

    ${media.phone`
        display: none;
    `}
  }
`

const TeamImage = styled(Image)`
  width: 51.42vw;
  padding-bottom: 75%;
  //   padding-bottom: 41%;

  ${media.phone`
		display: none;
	`}
`

// Drawer

const drawerStyles = css`
  background: ${colours.offWhite};
  padding-bottom: 0 !important;
`

const ScrollWrapper = styled.div``

// Person

const DrawerTop = styled.div``
const Toggle = styled.div``
const PersonImage = styled(Image)``

const Person = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${ScrollWrapper} {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 32px;

    ${media.phone`
			padding-bottom: 18px;
		`}

    > ${Heading} {
      margin-top: 128px;

      ${media.phone`
				margin-top: 48px;
			`}
    }
  }

  ${DrawerTop} {
    display: flex;
    justify-content: space-between;
    width: 100%;

    position: absolute;
    top: 32px;
    right: 0;
    left: 0;
    padding: 0 40px;
    z-index: 2;
    background: ${colours.offWhite};
    height: 55px;

    ${media.phone`
			padding: 0 20px;
			top: 0;
			padding-top: 18px;
		`}

    ${Toggle} {
      transition: color 0.1s ease;
      cursor: pointer;

      &:hover {
        color: white;
      }
    }
  }

  ${Text} {
    margin-top: 140px;

    &,
    p {
      ${type.bodySmall}
    }
  }

  ${Subheading} {
    margin-top: auto;
    ${type.caption};
  }

  ${PersonImage} {
    display: none;
  }

  ${media.phone`
		${ScrollWrapper} {
			> ${Heading} {
				${type.bodySmall};
			}
		}

		${Text} {
			margin-top: 16px;
			margin-bottom: 26px;
		}

		${PersonImage} {
			display: flex;
			min-height: 328px;
			width: 100%;
			margin-top: 58px;
		}
	`}
`

export const query = graphql`
  query {
    allWordpressInfopages(filter: { slug: { eq: "office-expertise" } }) {
      nodes {
        title
        acf_json
      }
    }
  }
`

export default OfficeExpertise
